$settings-class: ".#{$class-component-prefix}settings";

#{$settings-class} {
  display: flex;
  flex-direction: column;

  &__section {
    @extend .b2-o-frame__background;
    display: flex;
    flex-flow: wrap row;
    margin-bottom: $space-small;
  }

  &__information {
    display: flex;
    flex-direction: column;
    margin-top: $space-medium;
  }

  &__information-title {
    margin-bottom: $space-large;
  }

  &__information-section {
    display: flex;
    flex-direction: column;
    margin-bottom: $space-large;
  }

  &__section-title {
    color: $palette-14;
    font-weight: $font-weight-bold;
    margin-bottom: $space-small;
    text-transform: uppercase;
  }

  &__section-data {
    display: flex;
  }

  &__section-key {
    font-weight: bold;
    font-size: $font-size-small;
    width: 100px;
  }

  &__section-value {
    font-size: $font-size-small;
  }
}

$banner-class: ".#{$class-component-prefix}banner";

#{$banner-class} {
  margin: 0;

  &__wrapper {
    display: flex;
    justify-content: center;
  }

  &__image {
    flex: 1;
    margin-right: $space-large;
    padding: $space-xsmall;

    img {
      display: block;
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }

  &__box {
    height: 210px;
    padding: 20px;
    flex: 1;
    margin: 0 30px 0 0;
    background-color: $palette-01;
    display: flex;
    justify-content: flex-end;

    &:last-child {
      margin: 0;
    }
  }

  &__content {
    flex: 1;
    flex-flow: column;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin: 0 0 0 auto;
    max-width: 200px;
    padding: 23px 0 15px;

    &-title {
      font-weight: $font-weight-light;
      font-size: 22px;
      line-height: 26px;
      letter-spacing: -0.6px;
      text-transform: uppercase;

      div {
        p {
          strong {
            margin: 0 0 25px;
            font-weight: $font-weight-bold;
          }

          em {
            font-style: italic;
          }
        }
      }

      b {
        font-weight: $font-weight-medium;
        letter-spacing: -0.2px;
      }
    }
  }

  &__shop-link {
    margin: 0 0 1px -1px;
    display: block;

    a {
      font-size: 0;
      margin: 0 0 0 1px;
      display: block;
      cursor: default;
    }

    &-text {
      font-weight: $font-weight-bold;
      margin: 0 9px -1px 0;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.3px;
      color: $palette-06;
      display: inline-block;
      vertical-align: middle;
    }

    .custom-icon {
      position: relative;
      height: 17px;
      width: 17px;
      padding: 1px;
      margin: 0 0 -2px;
      font-size: 12px;
      border-radius: 100%;
      color: #ffffff;
      background-color: $palette-34;
      display: inline-block;
      text-align: center;
      vertical-align: middle;

      &::before {
        position: absolute;
        left: 5px;
        bottom: 1px;
      }
    }
  }
}
/*

section#home-banners {
  margin: 0 0 58px;

  .banner-wrapper {
    
  }

  .banner-image {
    
  }

  .banner-box {
    

    .banner-content {
      
    }

    .banner-title h3 {
      
    }

    .banner-shop-link {
      
    }

    .banner-shop-link a {
      
    }

    .banner-shop-link a .text {
      
    }

    .banner-shop-link a .custom-icon {
      position: relative;
      height: 17px;
      width: 17px;
      padding: 1px;
      margin: 0 0 -2px;
      font-size: 18px;
      border-radius: 100%;
      color: $color-white;
      background-color: $color-yellow-1;
      display: inline-block;
      text-align: center;
      vertical-align: middle;

      &::before {
        position: absolute;
        left: 5px;
        bottom: 1px;
      }
    }

    .banner-link-box {
      display: block;
    }

    .banner-from {
      display: block;

      p {
        font-weight: $font-weight-regular;
        margin: 0 0 2px -3px;
        font-size: 12px;
        line-height: 20px;
        color: $color-black;
        text-transform: uppercase;
      }
    }

    .banner-price-box {
      display: flex;

      .banner-price {
        margin: 0 10px 0 5px;
        display: inline-block;
      }

      .price-currency {
        font-weight: $font-weight-bold;
        font-size: 16px;
        line-height: 10px;
        color: $color-black;
        display: inline-block;
        vertical-align: top;
      }

      .price-number {
        font-weight: $font-weight-bold;
        margin: 0 0 0 -3px;
        font-size: 29px;
        line-height: 20px;
        color: $color-black;
        display: inline-block;
        vertical-align: top;
      }

      .price-trailing {
        font-weight: $font-weight-medium;
        margin: 0 0 0 -4px;
        font-size: 16px;
        line-height: 10px;
        display: inline-block;
        vertical-align: top;
        color: $color-black;
      }

      .custom-icon {
        display: inline-block;
        margin: 0 0 4px;
      }
    }
  }
}

@media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
  section#home-banners {
    margin: 0 0 37px;

    .banner-wrapper {
      flex-flow: column;
      align-items: center;
    }

    .banner-image {
      flex: 1;
      margin-right: $space-large;
      padding: $space-large;
    }

    .banner-box {
      width: calc(100% - 44px);
      margin: 0 0 20px;
      padding: 13px 0;
    }
  }
}

@media (max-width: $screen-sm-max) {
  section#home-banners {
    margin: 0 0 37px;

    .banner-wrapper {
      flex-flow: column;
      align-items: center;
    }

    .banner-image {
      margin: 0;
      padding: $space-large;
    }

    .banner-box {
      flex-direction: column;
      margin: 0 0 20px;
      padding: 13px 0;
      width: calc(100% - 44px);

      .banner-content {
        flex: 1;
        justify-content: center;
        margin: 0 $space-medium;
        max-width: 100%;
        width: 100%;
      }

      .banner-title {
        width: 100%;
      }

      .banner-title h3 {
        font-weight: $font-weight-light;
        font-size: $font-size-large;
        letter-spacing: -0.1px;
        text-transform: uppercase;

        b {
          font-weight: $font-weight-medium;
          letter-spacing: -0.2px;
        }
      }

      .banner-shop-link a .text {
        margin: 0 9px 0 0;
        font-size: $font-size-medium;
        line-height: 20px;
        letter-spacing: 0.2px;
      }

      .banner-shop-link a .custom-icon {
        height: 15px;
        width: 15px;
      }

      .banner-link-box {
        display: block;
      }

      .banner-from {
        display: block;

        p {
          font-weight: $font-weight-medium;
          margin: 0 0 2px;
          font-size: 6px;
          line-height: 6px;
          color: $color-black;
          text-transform: uppercase;
        }
      }

      .banner-price-box {
        .banner-price {
          margin: 0 6px 0 5px;
          display: inline-block;
        }

        .price-currency {
          font-size: 9px;
          line-height: 10px;
        }

        .price-number {
          font-size: 14px;
          line-height: 14px;
        }

        .price-trailing {
          font-size: 9px;
          line-height: 10px;
        }

        .custom-icon {
          margin: 0 0 4px;
        }
      }
    }
  }
}
*/

$shop-user-class: ".#{$class-component-prefix}shop-user";

#{$shop-user-class} {
  font-size: $font-size-medium;

  &:nth-child(even) {
    background-color: rgba(67, 70, 127, 0.02);
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(67, 70, 127, 0.05);
  }

  &__name {
    padding: $space-regular;
  }

  &__email {
    padding: $space-small;
  }

  &__customer {
    padding: $space-small;
  }

  &__salesman {
    &--red {
      color: $palette-32;
      padding: $space-small;
    }

    &--green {
      color: $palette-30;
      padding: $space-small;
    }
  }

  &__enabled {
    padding: $space-small;
  }

  &__locked {
    color: $palette-32;
  }

  &__unlocked {
    color: $palette-14;
  }
}

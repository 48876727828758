$setting-class: ".#{$class-component-prefix}setting";

#{$setting-class} {
  display: flex;
  align-items: center;
  flex-basis: calc(33.3333% - 20px);
  margin: $space-small;
  padding: $space-small;
  transition: background-color 0.1s ease-in-out;

  &__icon {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.05);
    color: lighten($palette-14, 5%);
    display: flex;
    font-size: $font-size-large;
    justify-content: center;
    margin-right: $space-medium;
    padding: $space-regular;
    height: 55px;
    width: 55px;
  }

  &__data {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
    margin-bottom: $space-xsmall;
  }

  &__description {
    color: $palette-23;
    font-size: $font-size-small;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 6px;
    cursor: pointer;
  }
}

$tags-widget-class: ".#{$class-component-prefix}tags-widget";

#{$tags-widget-class} {
  &__button {
    font-size: $font-size-small;
    height: auto;
    margin: 0;
    padding: 0;
  }

  &__list {
    display: flex;
    direction: column;
    flex-wrap: wrap;
    margin-top: 15px;

    &:empty {
      margin-top: 0px;
    }
  }

  &__list-item {
    background-color: #dfe3e8;
    border-radius: 5px;
    color: #454f5b;
    font-size: 12px;
    margin: 5px 5px 0 0;
    padding: 8px;

    &:hover {
      cursor: pointer;
    }

    &:after {
      content: "X";
      color: #798c9c;
      font-size: 10px;
      padding-left: 7px;
    }
  }
}

$toggle-option-class: ".#{$class-component-prefix}toggle-option";

#{$toggle-option-class} {
  align-items: center;
  border-top: 1px solid $palette-22;
  display: flex;
  margin-bottom: $space-medium;
  padding-top: $space-medium;

  &__icon {
    color: $palette-14;
    font-size: $font-size-large;
    margin-right: 20px;
    min-width: 30px;
  }

  &__title {
    align-self: center;
    flex-grow: 1;
    font-size: $font-size-small;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__actions {
    button {
      margin-left: $space-small;
    }
  }
}

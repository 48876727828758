/**
* Checkbox component styles
*/

// Setup class name
$checkbox-class: ".#{$class-component-prefix}checkbox";

#{$checkbox-class} {
  display: inline-block;
  user-select: none;
  width: 100%;

  &__input {
    display: none;
  }

  &__label {
    flex: auto;
    font-size: $checkbox-label-font-size;
    line-height: $checkbox-line-height;
  }

  &__wrapper {
    cursor: pointer;
    display: inline-flex;
    width: 100%;
  }

  &--not-checked {
    display: block;
    fill: $checkbox-idle-color;
  }

  &--is-checked {
    display: none;
  }

  &--align-right {
    #{$checkbox-class}__wrapper {
      flex-direction: row-reverse;
    }
  }

  &:hover {
    #{$checkbox-class}__icon {
      fill: $checkbox-hover-color;
    }
  }
}

#{$checkbox-class}__input:checked + #{$checkbox-class}__wrapper {
  #{$checkbox-class} {
    &--is-checked {
      display: block;
      fill: $checkbox-selected-color;
    }

    &--not-checked {
      display: none;
    }
  }
}

#{$checkbox-class}__input:disabled + #{$checkbox-class}__wrapper {
  cursor: not-allowed;

  #{$checkbox-class} {
    &--is-checked {
      fill: $checkbox-disabled-color;
    }

    &--not-checked {
      fill: $checkbox-checked-disabled-color;
    }

    &__label {
      color: $checkbox-label-disabled-color;
    }
  }
}

$page-class: ".#{$class-object-prefix}page";

#{$page-class} {
  &__offset {
    margin-left: $space-medium;
  }

  &__header {
    align-items: center;
    display: flex;
    margin-bottom: $space-medium;
    user-select: none;
  }

  &__navigation {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }

  &__back {
    margin-right: $space-medium;
  }

  &__title {
    flex-grow: 1;
  }

  &__spacer {
    flex-grow: 1;
  }

  &__actions > * {
    margin-left: 10px;
  }

  &__content {
    width: 100%;
  }

  &__content-with-bg {
    @extend .b2-o-frame__background;
    width: 100%;
  }
}

$shipping-class: ".#{$class-component-prefix}shipping";

#{$shipping-class} {
  &__add {
    color: $palette-13;
    font-size: $font-size-small;
    text-decoration: underline;
  }

  &__list {
    margin-top: $space-medium;
  }
}

$sync-class: ".#{$class-component-prefix}sync";

#{$sync-class} {
  &__data-types {
    margin-top: $space-medium;
  }

  &__data-title {
    color: $palette-17;
    flex-grow: 1;
    font-weight: 600;
  }

  &__data-content {
    margin-top: $space-medium;
  }

  &__events {
    display: flex;
    flex-direction: column;
    margin-top: $space-large;
  }

  &__events-log {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.05);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: $space-medium 0;
    padding: $space-medium;
  }

  &__events-innerlog {
    font-size: $font-size-small;
    max-height: 500px;
    overflow-y: scroll;
  }
}

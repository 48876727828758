$files-modal-class: ".#{$class-component-prefix}files-modal";

#{$files-modal-class} {
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  &__selected {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
  }

  &__icon {
    color: $palette-14;
    font-size: $font-size-xxxlarge;
    margin-bottom: $space-large;
  }

  &__name {
    margin-bottom: $space-xsmall;
  }

  &__size {
    color: $palette-23;
    font-size: $font-size-small;
  }

  &__button {
    margin-top: $space-xlarge;
  }
}

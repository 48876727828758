$shipping-item-class: ".#{$class-component-prefix}shipping-item";

#{$shipping-item-class} {
  display: flex;
  flex-direction: column;
  margin-top: $space-medium;

  &__id {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    margin-bottom: $space-small;
  }

  &__name {
    font-size: $font-size-small;
    margin-bottom: $space-xsmall;
  }

  &__info {
    display: flex;
  }

  &__description {
    color: $palette-23;
    flex-grow: 1;
    font-size: $font-size-small;
    padding-top: $space-xxsmall;
  }

  &__price {
    color: $palette-23;
    font-size: $font-size-small;
    margin-right: $space-medium;
    padding-top: $space-xxsmall;
  }

  &__button {
    color: $palette-13;
    font-size: $font-size-medium;
    padding: 0 $space-small $space-small $space-small;

    &:hover {
      cursor: pointer;
    }
  }
}

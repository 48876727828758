$featured-details-class: ".#{$class-component-prefix}featured-details";

#{$featured-details-class} {
  margin: 0;

  &__form {
    margin-bottom: $space-large;
  }

  &__spacer {
    margin-bottom: $space-medium;
  }

  &__preview {
    &-title {
      font-weight: $font-weight-bold;
    }
  }

  &__banner {
    margin-top: $space-medium;
  }
}

$categories-widget-class: ".#{$class-component-prefix}categories-widget";

#{$categories-widget-class} {
  &__button {
    font-size: $font-size-small;
    height: auto;
    margin: 0;
    padding: 0;
  }

  &__list {
    display: flex;
    direction: column;
    flex-wrap: wrap;
    margin-top: 15px;

    &:empty {
      margin-top: 0px;
    }
  }

  &__hint {
    color: $palette-06;
    font-size: $font-size-small;
    text-align: center;
  }

  &__item {
    border-radius: 5px;
    color: #454f5b;
    font-size: 12px;
    margin: 5px 0 0 0;
    padding: 8px;
    width: 100%;

    transition: background-color 0.1s;

    &:hover {
      background-color: rgba($palette-14, 0.2);
      cursor: pointer;
    }

    &:after {
      content: "X";
      color: #798c9c;
      float: right;
      font-size: 12px;
    }
  }
}

$text-input-class: ".#{$class-component-prefix}text-input";

#{$text-input-class} {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  &__label {
    @include input-label();
  }

  &__input {
    @include input-container();

    &:not(:read-only) {
      @include input-focus($text-input-class);
    }

    &::placeholder {
      color: $text-input-placeholder-color;
      user-select: none;
    }

    &:read-only {
      background-color: $text-input-read-only-bg-color;
      color: $text-input-read-only-text-color;
      user-select: none;
    }
  }

  &:hover {
    @include input-hover($text-input-class);
  }

  &--has-error {
    @include input-error($text-input-class);
  }

  &--is-disabled {
    @include input-disabled($text-input-class);
  }
}

$modules-class: ".#{$class-component-prefix}modules";

#{$modules-class} {
  &__item {
    align-items: center;
    border-bottom: 1px solid #eee;
    display: flex;
    padding: $space-small;

    &:last-child {
      border: 0;
      margin-bottom: 0;
    }
  }

  &__icon {
    color: $palette-13;
    font-size: $font-size-large;
    margin-right: $space-medium;
    width: 40px;
  }

  &__title {
    flex-grow: 1;
  }

  &__action {
    margin-left: $space-xsmall;

    &:hover {
      cursor: pointer;
    }
  }
}

/**
* Global variables
*/

// Global class Prefix
$class-library-prefix: "b2-" !default;
$class-object-prefix: "#{$class-library-prefix}o-" !default;
$class-component-prefix: "#{$class-library-prefix}c-" !default;

// Font sizing
$font-size-xxsmall: 0.6rem !default; //10px
$font-size-xsmall: 0.75rem !default; //12px
$font-size-small: 0.875rem !default; //14px
$font-size-medium: 1rem !default; // 16px
$font-size-large: 1.25rem !default; // 20px
$font-size-xlarge: 1.75rem !default; //28px
$font-size-xxlarge: 2.5rem !default; //40px
$font-size-xxxlarge: 3.5rem !default; //56px

// Font weight
$font-weight-light: 300 !default;
$font-weight-regular: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 600 !default;

// Font Family
$font-family: $font-family-roboto;

// Border Radius
$border-radius: 3px !default;

// Transitions
$transition-default-delay: 0.3s !default;
$transition-default-function: ease-in-out !default;

// Placeholders
$placeholder-color: rgba($palette-05, $shade-definition-50) !default;

/**
* ----- COMPONENTS -------
*/

/**
* BUTTON
*/
$button-height: $space-xlarge !default;
$button-spacing: $space-medium;
$button-font-size: $font-size-medium !default;
$button-border-radius: $border-radius !default;
// Small
$button-small-height: $space-large !default;
$button-small-spacing: $space-small !default;
$button-small-font-size: $font-size-small !default;
// Colours
$button-bg-color: $palette-01 !default;
$button-text-color: $palette-12 !default;
// Secondary button
$button-secondary-bg-color: $palette-03 !default;
$button-secondary-text-color: $palette-05 !default;
// Link
$button-link-color: $palette-12 !default;
$button-link-color-hover: $palette-07 !default;
$button-link-color-active: $palette-07 !default;
$button-link-color-disabled: $palette-04 !default;
// Link Secondary
$button-link-secondary-color: $palette-08 !default;
// Spacing
$icon-text-spacing: $space-xsmall !default;

/**
* TEXT INPUT
*/
$text-input-border-radius: $border-radius !default;
$text-input-border-width: 0.025rem !default;

$text-input-bg-color: $palette-19 !default;
$text-input-height: $space-xlarge !default;
$text-input-spacing: 0 $space-small 0 $space-small !default;
$text-input-font-size: $font-size-small !default;
$text-input-width: 100% !default;
$text-input-placeholder-color: $placeholder-color !default;

// Label
$text-label-font-size: $font-size-xsmall !default;

// STATES
// Hover
$text-input-hover-border-color: $palette-14 !default;
$text-input-border-width: 1px !default;

// Error
$text-input-error-border-color: $palette-01 !default;
$text-input-error-text-color: $palette-01 !default;

// Readonly
$text-input-read-only-bg-color: $palette-06 !default;
$text-input-read-only-text-color: $palette-08 !default;

/**
* CHECKBOX
*/
$checkbox-label-font-size: $font-size-small !default;
$checkbox-line-height: 24px !default;

$checkbox-selected-color: $palette-14 !default;
$checkbox-hover-color: $palette-13 !default;
$checkbox-idle-color: $palette-18 !default;
$checkbox-disabled-color: $palette-03 !default;
$checkbox-checked-disabled-color: $palette-03 !default;
$checkbox-label-disabled-color: $palette-07 !default;

/**
* TEXT
*/
// xSmall
$text-xsmall-font-size: $font-size-xsmall !default;
$text-xsmall-line-height: $font-size-xsmall * 1.5 !default;
// Small
$text-small-font-size: $font-size-small !default;
$text-small-line-height: $font-size-small * 1.5 !default;
// Medium
$text-medium-font-size: $font-size-medium !default;
$text-medium-line-height: $font-size-medium * 1.5 !default;
// Large
$text-large-font-size: $font-size-large !default;
$text-large-line-height: $text-large-font-size * 1.5 !default;
// xLarge
$text-xlarge-font-size: $font-size-xlarge !default;
$text-xlarge-line-height: $font-size-xlarge * 1.5 !default;
// xxLarge
$text-xxlarge-font-size: $font-size-xxlarge !default;
$text-xxlarge-line-height: $font-size-xxlarge * 1.5 !default;
// xxxLarge
$text-xxxlarge-font-size: $font-size-xxxlarge !default;
$text-xxxlarge-line-height: $font-size-xxxlarge * 1.5 !default;
$text-underline-color: $palette-08 !default;
$text-underline-spacing: $space-medium !default;

/**
* MODAL
*/

$modal-max-width: 720px !default;
$modal-min-width: 720px !default;
$modal-max-width-larger: 940px !default;
$modal-footer-border-top: 1px solid $palette-04 !default;
$modal-spacing: $space-medium !default;
$modal-border-radius: $border-radius !default;
$modal-close-button-color: $palette-08 !default;

$products-class: ".#{$class-component-prefix}products";

#{$products-class} {
  &__search {
    display: flex;
  }

  &__search-editing {
    flex-shrink: 0;
    margin-left: $space-medium;
  }
}

$product-class: ".#{$class-component-prefix}product";

#{$product-class} {
  font-size: $font-size-medium;

  &:nth-child(even) {
    background-color: rgba(67, 70, 127, 0.02);
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(67, 70, 127, 0.05);
  }

  &__image {
    padding: $space-small;
    max-width: 70px;
  }

  &__image-bg {
    background-color: #f1f1f1;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 70px;
    width: 70px;

    img {
      object-fit: cover;
      height: 70px;
      width: 70px;
    }

    i {
      color: #ccc;
      font-size: $font-size-xxlarge;
      text-align: center;
    }
  }

  &__title {
    padding: $space-regular;
  }

  &__exposed {
    padding: $space-small;

    i {
      color: $palette-14;
      font-size: $font-size-medium;
      margin-right: $space-medium;
    }
  }

  &__price {
    padding: $space-small;
  }

  &__quantity {
    padding: $space-small;
  }
}

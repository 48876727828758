/**
* Button component styles
*/

// Setup class name
$button-class: ".#{$class-component-prefix}button";

#{$button-class} {
  align-items: center;
  background-color: $button-bg-color;
  border: 0;
  border-radius: $button-border-radius;
  color: $button-text-color;
  cursor: pointer;
  display: inline-flex;
  font-size: $button-font-size;
  height: $button-height;
  outline: 0;
  padding: 0 $button-spacing;
  transition: background-color 0.1s ease-in-out;
  user-select: none;

  &--pagination {
    @extend #{$button-class}--border-only;
    background-color: #fff;
    font-size: $font-size-xsmall;
    justify-content: center;
    margin: 0 $space-xxsmall $space-xxsmall $space-xxsmall;
    padding: 0;
    width: 60px;

    &--active {
      background-color: $palette-14;
      color: $palette-19;
      font-size: $font-size-small;
    }
  }

  &--pagination-bound {
    @extend #{$button-class}--border-only;
    background-color: lighten($palette-14, 40%);
    font-size: $font-size-xsmall;
    margin: 0 $space-xxsmall;

    &--active {
      background-color: $palette-14;
      color: $palette-19;
      font-size: $font-size-small;
    }
  }

  &--pagination-bound-inner {
    @extend #{$button-class}--border-only;
    background-color: lighten($palette-14, 40%);
    font-size: $font-size-xsmall;
    margin: 0 $space-small;

    &--active {
      background-color: $palette-14;
      color: $palette-19;
      font-size: $font-size-small;
    }
  }

  &--border-only {
    background: $palette-19;
    border: 1px solid $palette-22;
    color: #212b36;
    font-size: $font-size-small;

    &:hover {
      background-color: rgba($palette-14, 0.5);
      border: 1px solid rgba($palette-14, 0.2);
      color: #fff;
    }

    &:disabled {
      background-color: mix(
        rgba($button-bg-color, 0.1),
        rgba($palette-13, 0.2)
      );
      cursor: not-allowed;

      #{$button-class}__label {
        color: rgba($button-text-color, $shade-definition-50);
      }
    }
  }

  &--save {
    background-color: $palette-30;
    color: #fff;
    font-size: $font-size-small;

    &:disabled {
      background-color: lighten($palette-30, 20%);
    }

    &:hover:disabled {
      cursor: default;
    }

    &:hover:not(:disabled) {
      background-color: mix(rgba(#000, 0.7), rgba($palette-30, 1));
    }
  }

  &--edit {
    background-color: $palette-31;
    color: #fff;
    font-size: $font-size-small;

    &:disabled {
      background-color: lighten($palette-31, 20%);
    }

    &:hover:disabled {
      cursor: default;
    }

    &:hover:not(:disabled) {
      background-color: mix(rgba(#000, 0.7), rgba($palette-31, 1));
    }
  }

  &--remove {
    background-color: $palette-32;
    color: #fff;
    font-size: $font-size-small;

    &:disabled {
      background-color: lighten($palette-32, 20%);
    }

    &:hover:disabled {
      cursor: default;
    }

    &:hover:not(:disabled) {
      background-color: mix(rgba(#000, 0.7), rgba($palette-32, 1));
    }
  }

  &--primary {
    background-color: $palette-14;
    color: $palette-19;
    font-size: $font-size-small;

    &:hover:not(:disabled) {
      background-color: $palette-13;
    }

    &:active {
      background-color: mix($button-bg-color, $palette-13, 85%);
    }

    &:disabled {
      background-color: mix(
        rgba($button-bg-color, 0.5),
        rgba($palette-13, 0.5)
      );
      cursor: not-allowed;

      #{$button-class}__label {
        color: rgba($button-text-color, $shade-definition-50);
      }
    }
  }

  &--secondary {
    background-color: $button-secondary-bg-color;
    color: $button-secondary-text-color;

    &:hover:not(:disabled) {
      opacity: $shade-definition-85;
    }

    &:active {
      background-color: mix($button-secondary-bg-color, $palette-13, 85%);
      color: $button-secondary-text-color;
    }

    &:disabled {
      background-color: mix(
        rgba($button-secondary-bg-color, 0.5),
        rgba($palette-13, 0.5)
      );
      color: rgba($button-secondary-text-color, $shade-definition-50);
      cursor: not-allowed;
    }
  }

  &__text {
    background-color: transparent;
    color: $button-link-color;
    padding: unset;

    &:hover:not(:disabled) {
      // background-color: unset;
      color: $button-link-color-hover;

      &#{$button-class}--has-icon .zi-c-icon {
        fill: $button-link-color-hover;
      }
    }

    &:active {
      background-color: unset;
      color: $button-link-color-active;
    }

    &:disabled {
      background-color: unset;
      color: $button-link-color-disabled;
    }

    > span {
      font-size: unset;
    }
  }

  &__text--secondary {
    color: $button-link-secondary-color;

    &#{$button-class}--has-icon {
      .zi-c-icon {
        fill: $button-link-secondary-color;
      }
    }
  }

  &--icon-only {
    .zi-c-icon {
      fill: $palette-05;
    }

    &:hover {
      background-color: $palette-03;

      .zi-c-icon {
        fill: $palette-05;
      }
    }
  }

  &--small {
    height: $button-small-height;
    padding: 0 $button-small-spacing;

    #{$button-class}__label {
      font-size: $button-small-font-size;
    }
  }

  &--has-icon {
    .zi-c-icon {
      fill: $button-link-color;
    }
  }

  &--icon-left {
    flex-direction: row-reverse;

    .zi-c-icon {
      margin-right: $icon-text-spacing;
    }
  }

  &--payment-small {
    background: $palette-19;
    border: 1px solid $palette-22;
    color: #212b36;
    padding: $space-small $space-medium;
    height: auto;

    &:hover:not(:disabled) {
      background-color: mix(rgba(#000, 0.7), rgba($palette-14, 1));
      color: #fff;
    }

    &:disabled {
      background: #fff;
      color: $palette-22;
    }
  }

  &--delete-small {
    background: darken($palette-32, 25%);
    border: 1px solid darken($palette-32, 25%);
    color: #fff;
    padding: $space-small $space-medium;
    height: auto;

    // background-color: $palette-32;
    // color: #fff;
    // font-size: $font-size-small;

    &:hover:not(:disabled) {
      background: darken($palette-32, 40%);
      border: 1px solid darken($palette-32, 40%);
      //background-color: mix(rgba(#000, 0.7), rgba($palette-32, 1));
    }

    &:disabled {
      background: #fff;
      color: $palette-22;
    }
  }
}

$customers-widget-class: ".#{$class-component-prefix}customers-widget";

#{$customers-widget-class} {
  &__action {
    color: $palette-14;
    font-size: $font-size-small;
  }

  &__customer {
    display: flex;
    flex-direction: column;
    margin-bottom: $space-medium;
  }

  &__image {
    height: 50px;
    margin-bottom: $space-medium;
    width: 50px;
  }

  &__name {
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
    margin: $space-xsmall 0;
  }

  &__email {
    color: $palette-14;
    font-size: $font-size-small;
    margin-bottom: 5px;
  }

  &__phone {
    color: $palette-10;
    font-size: $font-size-small;
    margin-bottom: 5px;
  }

  &__note {
    color: $palette-23;
    font-size: $font-size-small;
  }

  &__info {
    margin-bottom: $space-large;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__info-title {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    margin-bottom: $space-medium;

    &:empty {
      margin-bottom: 0;
    }
  }

  &__info-item {
    align-items: baseline;
    display: flex;
    margin-bottom: $space-xsmall;
  }

  &__item-left {
    font-size: $font-size-small;
    width: 100px;
  }

  &__item-right {
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
  }

  &__non-payer {
    color: $palette-32;
    font-size: $font-size-small;
  }

  &__address {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
  }

  &__address-header {
    display: flex;
    flex-direction: row;
  }

  &__address-title {
    flex-grow: 1;
    font-size: $font-size-xsmall;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__addresses {
    margin-top: 20px;

    &:empty {
      margin-top: 0;
    }
  }

  &__address-item {
    color: $palette-23;
    font-size: $font-size-small;
    padding: 3px 0;
  }
}

$featured-item-class: ".#{$class-component-prefix}featured-item";

#{$featured-item-class} {
  align-items: center;
  display: flex;
  margin-bottom: $space-small;

  &__banner {
    flex-grow: 1;
    margin-right: $space-large;
  }

  &__actions {
    display: flex;
  }

  &__action {
    margin-right: $space-xsmall;
  }
}

$featured-class: ".#{$class-component-prefix}featured";

#{$featured-class} {
  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: $space-large;
  }

  &__title {
    color: $palette-18;
    margin-bottom: $space-small;
  }

  &__description {
    color: $palette-18;
  }

  &__results {
    display: flex;
    flex-direction: column;
    margin: $space-large 0 0 0;
  }
}

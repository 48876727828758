$search-class: ".#{$class-component-prefix}search";

#{$search-class} {
  display: flex;
  flex: 1;
  flex-direction: column;

  &__section {
    margin-bottom: $space-large;
  }

  &__section-header {
    align-items: baseline;
    display: flex;
    margin-bottom: $space-small;
  }

  &__section-title {
    margin-right: $space-xsmall;
  }

  &__section-subtitle {
    flex-grow: 1;
  }

  &__section-button {
  }

  &__products,
  &__categories {
  }

  &__product,
  &__customer {
    align-items: center;
    background-color: white;
    border-radius: $space-xsmall;
    cursor: pointer;
    display: flex;
    margin: $space-small 0;
    padding: $space-regular;
    transition: box-shadow 0.1s ease-in-out;

    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.05);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;

    &:hover {
      background: lighten($palette-14, 46%);
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.15);
    }
  }

  &__product-image {
    align-items: center;
    background-color: #f1f1f1;
    display: flex;
    height: 70px;
    justify-content: center;
    margin-right: $space-medium;
    width: 70px;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    i {
      color: #ccc;
      font-size: $font-size-xxlarge;
      text-align: center;
    }
  }

  &__product-title {
    flex-grow: 1;
  }

  &__customer {
    padding: $space-medium;
  }

  &__customer-icon {
    color: lighten($palette-14, 10%);
    font-size: $font-size-xlarge;
    margin-right: $space-large;
  }

  &__customer-name {
    flex-grow: 1;
    font-weight: bold;
  }

  &__chevron {
    color: $palette-21;
    font-size: $font-size-medium;
  }
}

$product-details-class: ".#{$class-component-prefix}product-details";

#{$product-details-class} {
  &__loading {
    margin-top: 150px;
  }

  &__dropzone {
    min-height: 300px;
    width: 100%;

    &--empty {
      background-image: url("../../../../assets/icon_image.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 250px 150px;
      cursor: pointer;
      min-height: 300px;
      width: 100%;

      p {
        bottom: 0;
        color: $palette-16;
        font-size: 14px;
        position: absolute;
        text-align: center;
        width: 100%;
      }
    }

    p {
      display: none;
    }
  }

  &__btn-images {
    border: none;
    color: $palette-14;
    font-size: $font-size-small;
    height: 25px;
    line-height: 25px;
    padding: 0;
  }

  &__files {
    display: flex;
    flex-wrap: wrap;
  }

  &__file-item {
    border: 1px solid #aaa;
    cursor: pointer;
    flex: 1 0 25%;
    margin: 5px;
    position: relative;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    &:hover {
      #{$product-details-class}__image-overlay {
        background: rgba(0, 0, 0, 0.6);
      }

      #{$product-details-class}__image-overlay-icon {
        opacity: 1;
      }
    }
  }

  &__image-overlay {
    background: rgba(0, 0, 0, 0);
    display: flex;
    height: 100%;
    position: absolute;
    top: 0;
    transition: all 0.2s ease-in-out;
    width: 100%;
  }

  &__image-overlay-icons {
    height: 100%;
    width: 100%;
  }

  &__image-overlay-icon {
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
    height: 100%;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    width: 100%;

    i {
      font-size: 45px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  &__featured {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    padding: $space-small;
    position: absolute;
    top: 0;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
  }

  &__button {
    display: block;
    margin-bottom: $space-small;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__vat-dropdown {
    text-transform: capitalize;
  }

  &__warehouse-title,
  &__warehouse-quantity {
    font-size: $font-size-small;
    padding: $space-small;
  }

  &__statuses {
    display: flex;
    flex-direction: column;
  }
}

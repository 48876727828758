$images-modal-class: ".#{$class-component-prefix}images-modal";

#{$images-modal-class} {
  &__grid {
    display: flex;
    flex: 1;
    flex-flow: wrap row;
    max-height: 60vh;
    overflow-y: scroll;
  }

  &__item {
    margin-bottom: $space-large;
    user-select: none;
  }

  &__image-wrapper,
  &__image-wrapper--selected {
    position: relative;
    padding-top: 100%;
    width: 100%;

    img {
      bottom: 0;
      display: block;
      left: 0;
      height: 100%;
      object-fit: cover;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }

    &:after {
      content: "\A";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.4);
      opacity: 0;
      transition: all 0.2s;
      -webkit-transition: all 0.2s;
    }

    &:hover:after {
      opacity: 1;
      cursor: pointer;
    }

    #{$images-modal-class}__checkmark {
      opacity: 0;
    }
  }

  &__image-wrapper--selected {
    &:after {
      background: rgba(0, 0, 0, 0.4);
      opacity: 1;
    }

    #{$images-modal-class}__checkmark {
      opacity: 1;
    }
  }

  &__checkmark {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    z-index: 3;

    i {
      color: #fff;
      font-size: $font-size-xxlarge;
    }
  }
}

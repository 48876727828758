$wrapper-class: ".#{$class-object-prefix}wrapper";

body {
  background-color: $palette-01;
  font-family: $font-family-roboto;
}

#{$wrapper-class} {
  &__content {
    background-color: $palette-15;
    display: flex;
    flex: 1;
    flex-direction: row;
    padding-top: $header-height;
  }

  &__main {
    background-color: $palette-01;
    margin-left: $sidebar-width-extended;
    padding: 25px 0;
    top: $header-height;
    width: calc(100% - 250px);
  }

  &__inner {
    padding: 15px 0;
    width: 100%;
  }

  &__sidebar {
    background-color: $palette-01;
    bottom: 0;
    left: 0;
    position: fixed;
    top: $header-height;
    width: $sidebar-width-extended;
  }

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
  }

  // // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    &__sidebar {
      width: $sidebar-width-collapsed;
    }

    &__main {
      margin-left: $sidebar-width-collapsed;
      padding: 25px 10px;
      width: calc(100% - 70px);
    }
  }

  // // Large devices (desktops, less than 1200px)
  @media (max-width: 1199.98px) {
  }
}

.cropper-container {
  direction: ltr;
  font-size: 0;
  line-height: 0;
  position: relative;
  touch-action: none;
  user-select: none;

  & img {
    display: block;
    height: 100%;
    image-orientation: 0deg;
    max-height: none !important;
    max-width: none !important;
    min-height: 0 !important;
    min-width: 0 !important;
    width: 100%;
  }
}

.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.cropper-wrap-box,
.cropper-canvas {
  overflow: hidden;
}

.cropper-drag-box {
  background-color: #fff;
  opacity: 0;
}

.cropper-modal {
  background-color: #000;
  opacity: 0.5;
}

.cropper-view-box {
  display: block;
  height: 100%;
  outline-color: rgba(51, 153, 255, 0.75);
  outline: 1px solid #39f;
  overflow: hidden;
  width: 100%;
}

.cropper-dashed {
  border: 0 dashed #eee;
  display: block;
  opacity: 0.5;
  position: absolute;

  &.dashed-h {
    border-bottom-width: 1px;
    border-top-width: 1px;
    height: calc(100% / 3);
    left: 0;
    top: calc(100% / 3);
    width: 100%;
  }

  &.dashed-v {
    border-left-width: 1px;
    border-right-width: 1px;
    height: 100%;
    left: calc(100% / 3);
    top: 0;
    width: calc(100% / 3);
  }
}

.cropper-center {
  display: block;
  height: 0;
  left: 50%;
  opacity: 0.75;
  position: absolute;
  top: 50%;
  width: 0;

  &:before,
  &:after {
    background-color: #eee;
    content: " ";
    display: block;
    position: absolute;
  }

  &:before {
    height: 1px;
    left: -3px;
    top: 0;
    width: 7px;
  }

  &:after {
    height: 7px;
    left: 0;
    top: -3px;
    width: 1px;
  }
}

.cropper-face,
.cropper-line,
.cropper-point {
  display: block;
  height: 100%;
  opacity: 0.1;
  position: absolute;
  width: 100%;
}

.cropper-face {
  background-color: #fff;
  left: 0;
  top: 0;
}

.cropper-line {
  background-color: #39f;

  &.line-e {
    cursor: ew-resize;
    right: -3px;
    top: 0;
    width: 5px;
  }

  &.line-n {
    cursor: ns-resize;
    height: 5px;
    left: 0;
    top: -3px;
  }

  &.line-w {
    cursor: ew-resize;
    left: -3px;
    top: 0;
    width: 5px;
  }

  &.line-s {
    bottom: -3px;
    cursor: ns-resize;
    height: 5px;
    left: 0;
  }
}

.cropper-point {
  background-color: #39f;
  height: 5px;
  opacity: 0.75;
  width: 5px;

  &.point-e {
    cursor: ew-resize;
    margin-top: -3px;
    right: -3px;
    top: 50%;
  }

  &.point-n {
    cursor: ns-resize;
    left: 50%;
    margin-left: -3px;
    top: -3px;
  }

  &.point-w {
    cursor: ew-resize;
    left: -3px;
    margin-top: -3px;
    top: 50%;
  }

  &.point-s {
    bottom: -3px;
    cursor: s-resize;
    left: 50%;
    margin-left: -3px;
  }

  &.point-ne {
    cursor: nesw-resize;
    right: -3px;
    top: -3px;
  }

  &.point-nw {
    cursor: nwse-resize;
    left: -3px;
    top: -3px;
  }

  &.point-sw {
    bottom: -3px;
    cursor: nesw-resize;
    left: -3px;
  }

  &.point-se {
    bottom: -3px;
    cursor: nwse-resize;
    height: 20px;
    opacity: 1;
    right: -3px;
    width: 20px;

    @media (min-width: 768px) {
      height: 15px;
      width: 15px;
    }

    @media (min-width: 992px) {
      height: 10px;
      width: 10px;
    }

    @media (min-width: 1200px) {
      height: 5px;
      opacity: 0.75;
      width: 5px;
    }
  }

  &.point-se:before {
    background-color: #39f;
    bottom: -50%;
    content: " ";
    display: block;
    height: 200%;
    opacity: 0;
    position: absolute;
    right: -50%;
    width: 200%;
  }
}

.cropper-invisible {
  opacity: 0;
}

.cropper-bg {
  //background-image: url("../images/bg.png");
}

.cropper-hide {
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}

.cropper-hidden {
  display: none !important;
}

.cropper-move {
  cursor: move;
}

.cropper-crop {
  cursor: crosshair;
}

.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor: not-allowed;
}

.image-appear {
  opacity: 0;
}

.image-enter {
  opacity: 0;

  &--active {
    opacity: 0;
  }

  &--done {
    animation-duration: 0.5s;
    animation-name: image-fadeIn;
    animation-timing-function: ease-in-out;
    opacity: 1;
    transform: none;
    visibility: visible;
  }
}

.image-exit {
  display: none;
}

@keyframes image-fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

$notice-class: ".#{$class-component-prefix}notice";

#{$notice-class} {
  @include frame-style();
  padding: $space-medium $space-medium;

  align-items: center;
  display: flex;

  &:hover {
    cursor: pointer;
  }

  &__icon {
    color: $palette-13;
    font-size: $font-size-xlarge;
    margin-right: $space-large;
  }

  &__description {
    flex-grow: 1;
    font-size: $font-size-small;
  }

  &__arrow {
    color: $palette-21;
    font-size: $font-size-medium;
  }
}

$breadcrumbs-class: ".#{$class-component-prefix}breadcrumbs";

#{$breadcrumbs-class} {
  margin: $space-medium 0 $space-small 0;

  &__icon {
    color: #999;
    font-size: $font-size-xxsmall;
    margin-left: $space-xsmall;
  }

  &__item {
    color: $palette-17;
    font-size: $font-size-xsmall;
    margin-right: $space-xsmall;
    text-transform: capitalize;
  }

  &__item-main {
    color: $palette-17;
    font-size: $font-size-small;
    margin-right: $space-small;
    text-transform: capitalize;
  }
}

$roles-class: ".#{$class-component-prefix}roles";

#{$roles-class} {
  &__item {
    border-bottom: 1px solid #eee;

    &:last-child {
      border: 0;
    }
  }

  &__title {
    padding: $space-small;
  }

  &__description {
    padding: $space-small;
  }

  &__level {
    padding: $space-small;
  }

  &__actions {
    padding: $space-xsmall;
    text-align: right;
  }

  &__action {
    margin-left: $space-xsmall;

    &:hover {
      cursor: pointer;
    }
  }
}

$sidebar-class: ".#{$class-component-prefix}sidebar";

#{$sidebar-class} {
  border-right: 1px solid #dfe3e8;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: $sidebar-width-extended;

  &__menu-ul {
    list-style-type: none;
  }

  &__menu-li {
    margin: 0;

    &--active {
      #{$sidebar-class}__menu-item {
        background-color: rgba(67, 70, 127, 0.1);
        border-left: 5px solid $palette-14;

        i {
          color: $palette-14;
        }

        &__title {
          color: $palette-12;
          font-size: $font-size-small;
        }
      }
    }
  }

  &__menu-item {
    align-items: center;
    border-left: 5px solid transparent;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 15px 20px;
    transition: background-color 0.1s ease-in-out;
    text-align: center;

    i {
      color: $palette-16;
      font-size: 18px;
      height: 25px;
      margin-right: 20px;
      padding-top: 2px;
      width: 25px;
    }

    &:hover {
      background-color: rgba(67, 70, 127, 0.1);
    }
  }

  &__menu-item-title {
    color: $palette-17;
    display: inline;
    flex-grow: 1;
    font-size: $font-size-small;
    text-align: left;
  }

  &__menu-item-badge {
    background-color: $palette-14;
    border-radius: 6px;
    color: #fff;
    font-size: $font-size-xsmall;
    padding: 5px 8px;
  }

  &__submenu-ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__submenu-li {
    margin: 0;

    &--active {
      #{$sidebar-class}__submenu-item {
        background-color: rgba(67, 70, 127, 0.06);
        color: $palette-12;
        font-size: $font-size-small;

        i {
          color: $palette-12;
        }
      }
    }

    &:last-child {
      border-bottom: 1px solid #dfe3e8;
    }
  }

  &__submenu-item {
    color: $palette-17;
    display: flex;
    font-size: $font-size-small;
    padding: 18px 0 18px 20px;
    transition: background-color 0.1s ease-in-out;

    &:hover {
      background-color: rgba(67, 70, 127, 0.1);
    }

    i {
      color: rgba(0, 0, 0, 0.3);
      margin: 0 22px 0 $space-large;
    }
  }

  &__submenu-item-title {
  }

  &__space {
    flex-grow: 1;
  }

  &__footer {
    &__ul {
      list-style-type: none;
      margin: 0;
      padding: 20px 0 0 0;
    }

    &__li {
      margin: 0;
      &--active {
        #{$sidebar-class}__footer__item {
          background-color: rgba(67, 70, 127, 0.1);
          border-left: 5px solid $palette-14;

          i {
            color: $palette-14;
          }

          &__title {
            color: $palette-12;
          }
        }
      }
    }

    &__item {
      border-left: 5px solid transparent;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      padding: 15px 20px 15px 20px;
      transition: background-color 0.1s ease-in-out;
      text-align: center;

      i {
        align-self: center;
        color: $palette-16;
        font-size: 16px;
        width: 25px;
        margin-right: 20px;
      }

      &__title {
        color: $palette-17;
        display: inline;
        font-size: 14px;
      }

      &:hover {
        background-color: rgba(67, 70, 127, 0.1);
      }
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
}

// // Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  #{$sidebar-class} {
    width: $sidebar-width-collapsed;

    &__menu-item-title {
      display: none;
    }

    &__submenu-item-title {
      display: none;
    }
  }
}

// // Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

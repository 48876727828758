/**
* Bog standard spacing metrics
*
* FYI: 16px = 1rem
*/

$space-xxsmall: 0.1875rem; // 3px
$space-xsmall: 0.3125rem; // 5px
$space-small: 0.625rem; // 10px
$space-regular: 0.9375rem; // 15px
$space-medium: 1.25rem; // 20px
$space-large: 1.875rem; // 30px
$space-xlarge: 2.5rem; // 40px
$space-xxlarge: 3.75rem; // 60px

// Stack
$stack-xsmall: 0 0 $space-small 0;

$account-class: ".#{$class-component-prefix}account";

#{$account-class} {
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  flex: 1;
  flex-flow: wrap row;
  justify-content: center;
  padding: $space-medium 0;

  &:hover {
    cursor: pointer;
  }

  &:last-child {
    border: 0;
  }

  &__data {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__name {
    color: $palette-14;
    font-size: $font-size-medium;
    margin-bottom: $space-xxsmall;
  }

  &__login {
    color: $palette-23;
    font-size: $font-size-small;
  }

  &__permissions {
    font-size: $font-size-small;
  }
}

$widget-class: ".#{$class-object-prefix}widget";

#{$widget-class} {
  @extend .b2-o-frame__background;

  &__header {
    align-items: center;
    display: flex;
    margin-bottom: $space-medium;
  }

  &__title {
    color: $palette-17;
    flex-grow: 1;
    font-weight: 600;
  }

  &__actions {
    & > * {
      margin-left: $space-small;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__content {
  }
}

.b2-o-frame__background {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.05);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: $space-medium;
}

$modal-class: ".#{$class-component-prefix}modal";

#{$modal-class} {
  background-color: #fff;
  border-radius: $modal-border-radius;
  max-width: $modal-max-width;
  min-width: $modal-min-width;

  &--loading {
    background-color: #fff;
    border-radius: $modal-border-radius;
    max-width: auto;
    min-width: auto;
    max-height: auto;
    padding: 0 100px;
  }

  &--larger {
    #{$modal-class} {
      max-width: $modal-max-width-larger;
    }
  }

  &__outer {
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow-y: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
  }

  &__header {
    border-top-left-radius: $modal-border-radius;
    border-top-right-radius: $modal-border-radius;
    height: 50px;
    padding-left: $modal-spacing;
    padding-right: $modal-spacing;
  }

  &__header-actions {
    padding-top: 25px;
  }

  &__header-close {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    i {
      cursor: pointer;
      font-size: $font-size-large;
    }
  }

  &__header-text {
    color: $palette-07;
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    text-transform: capitalize;
  }

  &__header-search {
    margin-right: 20px;
    width: 200px;
  }

  &__body {
    padding: $modal-spacing;
  }

  &__footer {
    display: flex;
    margin-bottom: $modal-spacing;
    padding-left: $modal-spacing;
    padding-right: $modal-spacing;
  }

  &__footer-total {
    flex-grow: 1;
    font-size: $font-size-small;
    line-height: 30px;
  }

  &__footer-button {
    margin-left: $space-xsmall;
  }

  &__close-icon {
    margin-right: -10px;

    &.b2-c-icon-button .b2-c-icon {
      fill: $modal-close-button-color;
    }
  }

  &--is-frameless {
    #{$modal-class} {
      background-color: transparent;
    }

    .b2-c-icon-button:hover {
      background-color: unset;
    }
  }

  &--is-full-width {
    #{$modal-class} {
      height: 100vh;
      max-width: unset;
      min-width: unset;
      width: calc(100vw - 200px);

      &__empty-header {
        justify-content: flex-end;
      }

      &__close-icon .b2-c-icon {
        fill: $palette-12;
      }

      &__body {
        height: 100%;
        padding: unset;
      }
    }
  }

  &__screens--item {
    cursor: pointer;
  }

  &--images {
    background-color: #fff;
    max-height: 70vh;
    max-width: unset;
    min-width: unset;
    width: 70vw;

    &__empty-header {
      justify-content: flex-end;
    }

    &__close-icon .b2-c-icon {
      fill: $palette-12;
    }

    &__body {
      height: 100%;
      padding: unset;
    }
  }
}

/*
* Setting the state of the label for all input types (text, select etc)
*/
@mixin input-label() {
  color: $palette-17;
  display: block;
  font-size: $text-label-font-size;
  font-weight: $font-weight-bold;
  margin: $stack-xsmall;
  text-transform: uppercase;
  user-select: none;
}

/*
* Structure for the input container can be applied across inputs
*/
@mixin input-container() {
  background: $text-input-bg-color;
  border: 1px solid $palette-21;
  border-radius: $text-input-border-radius;
  display: block;
  font-size: $text-input-font-size;
  line-height: $text-input-height - ($text-input-border-width * 2);
  margin-bottom: $space-medium;
  min-height: 35px;
  //min-width: 300px;
  outline: 0;
  padding: $text-input-spacing;
  transition: all $transition-default-function $transition-default-delay;
  width: $text-input-width;
}

/*
* Structure for the input container across inputs for hover state
*/
@mixin input-hover($input-class-name) {
  #{$input-class-name}__input:not(:read-only) {
    border-color: $text-input-hover-border-color;
    box-shadow: inset 0 0 0 $text-input-border-width
      $text-input-hover-border-color;
  }

  #{$input-class-name}--has-error,
  #{$input-class-name}--is-disabled {
    box-shadow: unset;
  }
}

/*
* Structure for the input container across inputs for active state
*/
@mixin input-focus($input-class-name) {
  &:focus + #{$input-class-name}__label {
    color: $palette-14;
  }

  &:focus {
    border-color: $text-input-hover-border-color;
    box-shadow: inset 0 0 0 $text-input-border-width
      $text-input-hover-border-color;
  }
}

/*
* Structure for the input container across inputs for disabled state
*/
@mixin input-disabled($input-class-name) {
  #{$input-class-name}__input {
    background-color: $palette-15;
    cursor: not-allowed;
  }
}

@mixin input-error($input-class-name) {
  #{$input-class-name}__input {
    border-color: $palette-32;
    color: $palette-32;
  }
}

$menu-item-class: ".#{$class-component-prefix}menu-item";

#{$menu-item-class} {
  border-bottom: 1px solid #eee;
  padding: $space-xsmall;
  transition: background-color 0.1s ease-in-out;

  &:nth-child(even) {
    background-color: rgba(67, 70, 127, 0.02);
  }

  &__title {
    padding-left: $space-small;
  }

  &__spacer {
    flex-grow: 1;
  }

  &__actions {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    padding: $space-xsmall;
  }

  &__action {
    font-size: $font-size-medium;
    margin-left: $space-xsmall;
  }
}

$customer-class: ".#{$class-component-prefix}customer";

#{$customer-class} {
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }

  td {
    border: none;
    padding: $space-xsmall $space-small;
    vertical-align: middle;
  }

  &__data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0;
  }

  &__data-top {
    align-items: baseline;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
  }

  &__data-bottom {
    display: flex;
    flex-direction: row;
  }

  &__name {
    color: #000;
    font-weight: $font-weight-bold;
    margin-right: 10px;
  }

  &__location {
    font-size: $font-size-small;
    font-weight: $font-weight-light;
  }

  &__note-icon {
    margin-right: 10px;
  }

  &__note {
    color: $palette-23;
    font-size: $font-size-small;
  }

  &__status {
    color: $palette-32;
    font-size: $font-size-large;
  }

  &__orders {
    font-size: $font-size-small;
    text-align: right;
  }

  &__spent {
    font-size: $font-size-small;
    text-align: right;
  }

  &:nth-child(even) {
    background-color: rgba(67, 70, 127, 0.02);
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(67, 70, 127, 0.05);
  }
}

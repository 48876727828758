$shop-user-details-class: ".#{$class-component-prefix}shop-user-details";

#{$shop-user-details-class} {
  align-items: flex-start;

  &__customer {
    align-items: baseline;
    display: flex;
  }

  &__customers {
    margin-bottom: $space-large;

    &-item {
      display: flex;
      margin-bottom: $space-small;
    }

    &-name {
      flex-grow: 1;
      font-size: $font-size-small;
    }

    &-remove {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__name {
    font-size: $font-size-small;
    margin: $space-medium 0 0 0;
  }

  &__remove {
    color: $palette-14;
    font-size: $font-size-small;
    padding: $space-regular;

    &:hover {
      cursor: pointer;
    }
  }

  &__loader {
    animation: spin 0.5s linear infinite;
    border: 4px solid $palette-14;
    border-top: 4px solid #f3f3f3;
    border-radius: 50%;
    height: 35px;
    margin-top: 24px;
    width: 35px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

$image-uploader-widget-class: ".#{$class-component-prefix}image-uploader-widget";

#{$image-uploader-widget-class} {
  &__content {
    &__list {
      display: flex;
      direction: column;
      flex-wrap: wrap;
      margin-top: 15px;

      &:empty {
        margin-top: 0px;
      }
    }

    &__item {
      background-color: #dfe3e8;
      border-radius: 5px;
      color: #454f5b;
      font-size: 12px;
      margin: 5px 5px 0 0;
      padding: 8px;

      &:hover {
        cursor: pointer;
      }

      &:after {
        content: "X";
        color: #798c9c;
        font-size: 10px;
        padding-left: 7px;
      }
    }
  }

  &__dropzone {
    height: 200px;
    width: 100%;

    &--empty {
      background-image: url("../../../assets/icon_image.svg");
      background-position: top;
      background-repeat: no-repeat;
      background-size: 120px 150px;
      cursor: pointer;
      height: 200px;
      width: 100%;

      p {
        bottom: 0;
        color: $palette-16;
        font-size: 14px;
        position: absolute;
        text-align: center;
        width: 100%;
      }
    }

    p {
      display: none;
    }
  }

  &__files {
    display: flex;
    flex-wrap: wrap;

    &__item {
      cursor: pointer;
      flex: 1 0 25%;
      margin: 5px;
      position: relative;

      img {
        height: 185px;
        object-fit: cover;
        width: 100%;
      }

      &:hover {
        #{$image-uploader-widget-class}__image-overlay {
          background: rgba(0, 0, 0, 0.6);

          &__icon {
            opacity: 1;
          }
        }
      }
    }
  }

  &__image-overlay {
    background: rgba(0, 0, 0, 0);
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;

    transition: all 0.2s ease-in-out;

    &__icon {
      color: white;
      height: 100%;
      opacity: 0;
      transition: all 0.2s ease-in-out;
      width: 100%;

      i {
        font-size: 45px;
        left: 50%;
        margin-right: -50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
      }
    }
  }
}

$basic-input-widget-class: ".#{$class-component-prefix}basic-input-widget";

#{$basic-input-widget-class} {
  &__label {
    @include input-label();
    margin-top: $space-medium;
  }

  &__editor {
    font-family: $font-family-roboto;

    .public-DraftEditor-content {
      min-height: 150px;

      h1 {
        font-size: $font-size-xxlarge;
      }

      h2 {
        font-size: $font-size-xlarge;
      }

      h3 {
        font-size: $font-size-large;
      }

      a {
        color: $palette-14;
        font-weight: $font-weight-bold;
      }
    }
  }
}

$filters-class: ".#{$class-component-prefix}filters";

#{$filters-class} {
  &__header {
    margin-bottom: $space-large;
  }

  &__title {
    color: $palette-17;
    margin-bottom: $space-small;
  }

  &__subtitle {
    color: $palette-18;
  }
}

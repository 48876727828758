$product-filters-class: ".#{$class-component-prefix}product-filters";

#{$product-filters-class} {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: $space-medium;

  &__content {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-bottom: $space-small;
  }

  &__item {
    width: 200px;
    margin-right: $space-small;
  }

  &__selection {
    display: flex;
    flex-direction: column;
    margin-bottom: $space-medium;
  }

  &__selection-title {
    color: $palette-23;
    font-size: $font-size-small;
    margin: $space-small 0;
  }

  &__selected-items {
    display: flex;
    flex-flow: wrap row;
  }

  &__selection-item {
    @include no-selection();

    background-color: #edf0f3;
    border-radius: $space-small;
    color: #454f5b;
    font-size: $font-size-xsmall;
    margin: 0 $space-xsmall $space-xsmall 0;
    padding: $space-small $space-small;
    transition: background-color 0.1s;

    &:hover {
      background-color: darken(#edf0f3, 5%);
      cursor: pointer;
    }

    &:after {
      content: "X";
      color: #798c9c;
      font-size: 10px;
      padding-left: 7px;
    }
  }
}

$table-class: ".#{$class-component-prefix}table";

#{$table-class} {
  overflow-x: auto;
  width: 100%;

  &__element {
    width: 100%;

    thead {
      text-align: left;
    }
  }

  &__column-filter,
  &__column {
    @include no-selection();

    border-bottom: 1px solid #eee;
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    padding: $space-small;
    vertical-align: middle;
  }

  &__column-filter {
    &:hover {
      background-color: #f7f7f7;
      cursor: pointer;
    }
  }

  &__filter {
    &--active {
      color: #000;
      margin-left: $space-small;
    }

    &--inactive {
      color: transparent;
      margin-left: $space-small;
    }
  }
}

$slider-class: ".#{$class-component-prefix}slider";

#{$slider-class} {
  display: none;

  &:first-child {
    display: block;
  }

  &__wrapper {
    position: relative;
    height: 400px;
    padding: 0 20px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  &__image {
    background-color: $palette-24;
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__overlay {
    background-color: $palette-36;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    z-index: 2;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    max-width: 400px;
    z-index: 3;
  }

  &__subtitle p {
    font-weight: $font-weight-medium;
    margin: 0 0 5px;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.65px;
    color: $palette-33;
    text-transform: uppercase;
  }

  &__title {
    h2 {
      color: $palette-19;
      font-size: 56px;
      font-weight: $font-weight-light;
      letter-spacing: -1.4px;
      line-height: 58px;
      margin: 0 0 36px;
      text-transform: uppercase;

      strong {
        font-weight: $font-weight-bold;
      }

      em {
        font-style: italic;
      }
    }
  }
}

$filter-details-class: ".#{$class-component-prefix}filter-details";

#{$filter-details-class} {
  &__title {
    color: $palette-14;
    font-size: $font-size-large;
    margin-bottom: $space-small;
  }

  &__subtitle {
    margin-bottom: $space-large;
  }
}

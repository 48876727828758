$customer-details-class: ".#{$class-component-prefix}customer-details";

#{$customer-details-class} {
  margin-bottom: 50px;

  &__user {
    align-items: center;
    display: flex;
    margin-bottom: $space-small;
    padding-top: $space-small;
  }

  &__info {
    margin-bottom: $space-medium;
  }

  &__image {
    color: $palette-14;
    font-size: $font-size-xlarge;
    margin-right: $space-medium;
  }

  &__data {
  }

  &__name {
    font-weight: $font-weight-bold;
    margin-bottom: $space-small;
  }

  &__location {
    font-size: $font-size-small;
    margin-bottom: $space-xsmall;
  }

  &__customer-since {
    color: $palette-23;
    font-size: $font-size-small;
  }

  &__actions {
    color: $palette-14;
    font-size: $font-size-small;
  }

  &__note {
    margin-bottom: $space-medium;
  }

  &__recent-orders {
  }

  &__order {
    cursor: pointer;
    font-size: $font-size-small;

    td {
      padding: $space-small;
    }

    &:hover {
      background-color: $palette-01;
      transition: background-color 0.1s ease-in-out;
    }
  }

  &__disable {
    align-items: center;
    border-top: 1px solid $palette-22;
    display: flex;
    margin-bottom: 15px;
    padding-top: 15px;

    &__header {
      display: flex;
      flex-grow: 1;
    }

    &__icon {
      color: $palette-14;
      font-size: $font-size-large;
      margin-right: 20px;
      min-width: 30px;
    }

    &__title {
      align-self: center;
      font-size: $font-size-small;
      font-weight: bold;
      text-transform: uppercase;
    }

    &__actions {
      button {
        margin-left: 5px;
      }
    }
  }

  &__users-button {
    color: $palette-14;
    font-size: $font-size-small;
    height: auto;
  }

  &__logs {
    margin-top: 100px;

    &__header {
    }

    &__list {
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-top: $space-medium;
  }

  &__info-item {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: $space-small;
  }

  &__info-key {
    color: $palette-14;
    font-size: 18px;
    margin-right: $space-regular;
  }

  &__info-value {
    color: $palette-23;
    font-size: $font-size-small;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
  }

  &__button {
    display: block;
    margin-bottom: $space-small;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.b2-c-button--disable-user {
  background: $palette-19;
  border: 1px solid $palette-22;
  color: #212b36;
  padding: 8px 15px;
  height: auto;

  &:hover:not(:disabled) {
    background-color: mix(rgba(#000, 0.7), rgba($palette-14, 1));
    color: #fff;
  }

  &:disabled {
    background: #fff;
    color: $palette-22;
  }
}

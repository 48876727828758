$assets-class: ".#{$class-component-prefix}assets";

#{$assets-class} {
  &__item {
    margin-bottom: $space-large;
  }

  &__item-wrapper,
  &__item-wrapper--selected {
    background: #eee;
    padding-bottom: 75%;
    position: relative;
    width: 100%;

    &:after {
      content: "\A";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.4);
      opacity: 0;
      transition: all 0.2s;
      -webkit-transition: all 0.2s;
    }

    &:hover:after {
      opacity: 1;
      cursor: pointer;
    }

    &:hover {
      cursor: pointer;
    }

    #{$assets-class}__checkmark {
      opacity: 0;
    }
  }

  &__item-wrapper--selected {
    &:after {
      background: rgba(0, 0, 0, 0.4);
      opacity: 1;
    }

    #{$assets-class}__checkmark {
      opacity: 1;
    }
  }

  &__image {
    bottom: 0;
    display: block;
    left: 0;
    height: 100%;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  &__checkmark {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    z-index: 3;

    i {
      color: #fff;
      font-size: $font-size-xxlarge;
    }
  }
}

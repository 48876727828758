$general-class: ".#{$class-component-prefix}general";

#{$general-class} {
  &__email {
    align-items: flex-end;
    display: flex;
  }

  &__email-provider {
    flex-grow: 1;
    margin-right: $space-medium;
  }

  &__email-button {
  }

  &__gmail {
    display: flex;
    margin-top: $space-large;

    &:empty {
      margin: 0;
    }
  }

  &__gmail-holder {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  &__gmail-code {
    flex-grow: 1;
    margin-right: $space-medium;
  }

  &__gmail-code-button {
  }

  &__email-status {
    font-size: $font-size-small;
  }

  &__smtp {
    margin-top: $space-large;
  }

  &__code {
    align-items: center;
    margin-top: $space-medium;
  }

  &__code-input {
    flex-basis: 50%;
  }

  &__code-button {
    background-color: red;
    flex-basis: 50%;
  }
}

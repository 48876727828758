$order-modal-class: ".#{$class-component-prefix}order-modal";

#{$order-modal-class} {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__search {
    .b2-c-text-input__input {
      &::placeholder {
        color: #bbb;
      }
    }
  }

  &__products {
    max-height: 75vh;
    overflow-y: scroll;
  }

  &__product {
    border-bottom: 1px solid $palette-22;
    color: $palette-17;
    display: flex;
    font-size: $font-size-small;
    padding: 10px 0;
    transition: background-color 0.1s ease-in-out;

    &:nth-child(even) {
      background-color: rgba(67, 70, 127, 0.02);
    }

    &:last-child {
      border: none;
    }

    &:hover {
      background-color: rgba($palette-14, 0.1);
      cursor: pointer;
    }
  }

  &__product-checkbox {
    margin-right: $space-small;
    width: auto;
  }

  &__product-image {
    margin-right: $space-small;
  }

  &__product-title {
    flex-grow: 1;
  }

  &__product-price {
    margin-right: $space-medium;
  }
}

$category-class: ".#{$class-component-prefix}category";

#{$category-class} {
  border-bottom: 1px solid #eee;
  padding: $space-xsmall;
  transition: background-color 0.1s ease-in-out;

  &:nth-child(even) {
    background-color: rgba(67, 70, 127, 0.02);
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(67, 70, 127, 0.05);
  }

  &__image {
    padding: $space-small;
    max-width: 70px;
  }

  &__image-bg {
    background-color: #f1f1f1;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 70px;
    width: 70px;

    img {
      object-fit: cover;
      height: 70px;
      width: 70px;
    }

    i {
      color: #ccc;
      font-size: $font-size-xxlarge;
      text-align: center;
    }
  }

  &__title {
    padding-left: $space-small;
  }

  &__actions {
    justify-content: flex-end;
    display: flex;
    padding-right: $space-small;
  }

  &__action {
    font-size: $font-size-medium;

    &:first-child {
      margin-right: $space-xsmall;
    }
  }
}

$pagination-class: ".#{$class-component-prefix}pagination";

#{$pagination-class} {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: $space-medium;

  &__item {
    margin: 0 $space-xsmall;
  }
}

$order-class: ".#{$class-component-prefix}order";

#{$order-class} {
  font-size: $font-size-small;

  &--non-payer {
    color: $palette-32;
  }

  &:nth-child(even) {
    background-color: rgba(67, 70, 127, 0.02);
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(67, 70, 127, 0.05);
  }

  &__creation-date {
    padding: $space-regular $space-regular $space-regular $space-small;
  }

  &__customer {
    padding: $space-regular $space-regular $space-regular $space-small;
  }

  &__email {
    padding: $space-regular $space-regular $space-regular $space-small;
  }

  &__status {
    &--red {
      color: $palette-32;
      text-align: center;
    }

    &--green {
      color: $palette-30;
      text-align: center;
    }
  }
}

$text-area-class: ".#{$class-component-prefix}textarea";

#{$text-area-class} {
  border: 1px solid #ddd;
  padding: $space-small $space-small;
  resize: none;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:disabled {
    background-color: #f4f4f4;
    cursor: not-allowed;
  }
}

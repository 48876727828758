$shipping-modal-class: ".#{$class-component-prefix}shipping-modal";

#{$shipping-modal-class} {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    padding-right: 10px;
  }

  &__price {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
  }

  &__price-label {
    @extend .b2-c-text-input__label;
  }

  &__price-input {
    height: 41px;
    padding: 0 $space-small;
  }

  &__description {
  }

  &__button {
    margin-left: $space-small;
  }
}

// Setup class name
.b2-c-frame-background {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: $space-medium;

  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.05);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  &__search {
    margin-bottom: $space-small;
  }
}

@mixin no-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin frame-style {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: $space-medium;

  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.05);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

$header-class: ".#{$class-component-prefix}header";

#{$header-class} {
  background-color: $palette-11;
  display: flex;
  flex-direction: row;
  height: $header-height;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  &__company {
    align-items: center;
    background-color: $palette-10;
    color: white;
    display: flex;
    flex-direction: row;
    padding-left: $space-medium;
    width: $header-company-width;
  }

  &__company-logo {
    margin-right: $space-small;
  }

  &__company-label {
    color: #fff;
  }

  &__search {
    flex-grow: 1;
    padding: $space-small 35px;

    input {
      background-color: $palette-12;
      border: 0;
      color: $palette-19;
      font-size: $font-size-small;
      height: 100%;
      margin: 0;
      transition: background-color 0.1s ease-in;
      width: 100%;

      &:active,
      &:focus {
        background-color: $palette-15;
        color: $palette-10;
      }
    }

    ::placeholder {
      color: #efefef;
      font-weight: $font-weight-regular;
      opacity: 0.9;
    }

    :-ms-input-placeholder {
      color: #efefef;
    }

    ::-ms-input-placeholder {
      color: #efefef;
    }
  }

  &__user {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 $space-medium;
    transition: background-color 0.1s ease-in-out;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: $header-user-width;

    &:hover {
      background-color: $palette-13;
      cursor: pointer;
    }
  }

  &__user-avatar {
    color: #fff;
    font-size: $font-size-xlarge;
    margin-right: $space-medium;
    padding: 0;
  }

  &__user-data {
    display: flex;
    flex-direction: column;
    line-height: 18px;
  }

  &__user-label {
    cursor: pointer;
    color: $palette-19;
    font-size: $font-size-xsmall;
    font-weight: $font-weight-thin;
    margin: 0;
  }

  &__user-sublabel {
    cursor: pointer;
    color: $palette-18;
    font-size: $font-size-xsmall;
    font-weight: $font-weight-thin;
    margin: 0;
    text-transform: capitalize;
  }

  &__user-dropdown {
    background-color: $palette-19;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: auto;
    position: fixed;
    right: 0;
    top: $header-height;
    width: $header-user-width;
    z-index: 9999;
  }

  &__dropdown-ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__dropdown-li {
    margin: 0;
    padding: $space-medium $space-medium;

    i {
      align-self: center;
      color: $palette-16;
      font-size: $font-size-medium;
      margin-right: $space-medium;
      width: 25px;
    }

    &:hover {
      background-color: rgba(67, 70, 127, 0.1);
      cursor: pointer;
    }
  }

  &__dropdown-title {
    color: $palette-17;
    display: inline;
    font-size: $font-size-small;
  }

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    &__company {
      display: none;
    }
  }

  // // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    &__company {
      width: $sidebar-width-collapsed;
    }

    &__company-logo {
      display: none;
    }

    &__company-label {
      display: none;
    }
  }

  // // Large devices (desktops, less than 1200px)
  @media (max-width: 1199.98px) {
    &__user {
      width: 75px;
    }

    &__user-avatar {
      margin: 0;
    }

    &__user-data {
      display: none;
    }
  }
}

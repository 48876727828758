@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"),
    url("../../assets/fonts/Roboto-Regular.ttf") format("ttf");
}

$font-family-roboto: "Roboto", sans-serif;
$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;

html {
  font-size: $font-family-roboto;
}

body {
  font-family: $font-family-roboto;
}

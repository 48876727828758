$asset-details-class: ".#{$class-component-prefix}asset-details";

#{$asset-details-class} {
  &__content {
    margin-top: $space-xlarge;
  }

  &__cropper {
    width: 100%;

    .ReactCrop__image {
      width: 100%;
    }
  }

  &__cropper-title {
    color: $palette-17;
    font-size: $font-size-large;
    font-weight: 600;
    margin-bottom: $space-medium;
  }

  &__cropper-details {
    margin-bottom: $space-large;
  }

  &__preview {
    display: flex;
    flex-direction: column;
    margin-bottom: $space-large;
  }

  &__preview-title {
    color: $palette-17;
    font-size: $font-size-medium;
    font-weight: 600;
    margin-bottom: $space-small;
  }

  &__preview-image {
  }

  .cropper-modal {
    background-color: transparent;
    opacity: 0;
  }

  &__upload-button {
    margin-bottom: $space-medium;
  }

  &__dropzone {
    cursor: pointer;
    min-height: 300px;
    position: relative;
    width: 100%;

    &--empty {
      background-color: white;
      background-image: url("../../../../assets/icon_image.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 120px 150px;
      border: 1px solid #ddd;
      border-radius: 4px;
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.05);
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      cursor: pointer;
      min-height: 300px;
      position: relative;
      width: 100%;

      p {
        bottom: 0;
        color: $palette-16;
        display: block;
        font-size: $font-size-small;
        margin-bottom: $space-xlarge;
        position: absolute;
        text-align: center;
        width: 100%;
      }
    }

    &-image {
      width: 100%;

      img {
        display: block;
        height: auto;
        width: 100%;
      }
    }
  }
}

$slider-details-class: ".#{$class-component-prefix}slider-details";

#{$slider-details-class} {
  margin: 0;

  &__form {
    margin-bottom: $space-large;
  }

  &__spacer {
    margin-bottom: $space-large;
  }

  &__header {
    align-items: center;
    display: flex;
  }

  &__title {
    @include input-label();
    flex-grow: 1;
    margin-bottom: 0;
  }

  &__actions {
    & > * {
      margin-left: $space-small;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__dropzone {
    border: 1px solid $palette-22;
    border-radius: $space-xsmall;
    margin-top: $space-small;
    padding: $space-medium;
  }

  &__preview {
    &-title {
      font-weight: $font-weight-bold;
    }

    &-slider {
      margin-top: $space-small;
    }
  }
}

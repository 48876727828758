$text-class: ".#{$class-component-prefix}text";

#{$text-class} {
  display: block;

  &__xsmall {
    font-size: $text-xsmall-font-size;
    line-height: $text-xsmall-line-height;
  }

  &__small {
    font-size: $text-small-font-size;
    line-height: $text-small-line-height;
  }

  &__medium {
    font-size: $text-medium-font-size;
    line-height: $text-medium-line-height;
  }

  &__large {
    font-size: $text-large-font-size;
    line-height: $text-large-line-height;
  }

  &__xlarge {
    font-size: $text-xlarge-font-size;
    line-height: $text-xlarge-line-height;
  }

  &__xxlarge {
    font-size: $text-xxlarge-font-size;
    line-height: $text-xxlarge-line-height;
  }

  &__xxxlarge {
    font-size: $text-xxxlarge-font-size;
    line-height: $text-xxxlarge-line-height;
  }

  &__bold {
    font-weight: $font-weight-bold;
  }

  &__has-underline {
    &::after {
      background-color: $text-underline-color;
      content: "";
      display: block;
      height: 2px;
      margin-top: $text-underline-spacing;
      width: 30px;
    }
  }
}

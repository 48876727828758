$module-modal-class: ".#{$class-component-prefix}module-modal";

#{$module-modal-class} {
  &__subitems {
  }

  &__header {
    align-items: center;
    display: flex;
    margin: $space-medium 0;
  }

  &__title {
    color: $palette-23;
    flex-grow: 1;
    font-size: $font-size-small;
    text-transform: uppercase;
  }

  &__actions {
    color: $palette-13;

    &:hover {
      cursor: pointer;
    }
  }

  &__item {
    align-items: baseline;
    display: flex;
  }

  &__input {
    margin-right: $space-small;
  }

  &__spacer {
    flex-grow: 1;
  }

  &__action {
    margin-left: $space-xsmall;
  }
}

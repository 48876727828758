.mb-1 {
  margin-bottom: $space-xsmall;
}

.mb-2 {
  margin-bottom: $space-small;
}

.mb-3 {
  margin-bottom: $space-medium;
}

.mb-4 {
  margin-bottom: $space-large;
}

.mt-1 {
  margin-top: $space-xsmall;
}

.mt-2 {
  margin-top: $space-small;
}

.mt-3 {
  margin-top: $space-medium;
}

.mt-4 {
  margin-top: $space-large;
}

$category-modal-class: ".#{$class-component-prefix}category-modal";

#{$category-modal-class} {
  display: flex;
  flex-direction: row;

  &__image-grid {
    display: flex;
    flex-direction: column;
    margin-right: $space-xlarge;
  }

  &__header {
    @include input-label();
  }

  &__image {
    display: flex;
  }

  &__image-preview {
    align-items: center;
    background-color: #f1f1f1;
    display: flex;
    height: 130px;
    justify-content: center;
    margin-right: $space-small;
    width: 130px;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    i {
      color: #ccc;
      font-size: $font-size-xxlarge;
      text-align: center;
    }
  }

  &__image-actions {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__image-action {
    margin-bottom: $space-xsmall;
  }

  &__title {
    align-self: flex-start;
    flex-grow: 1;
  }

  &__filters {
    margin: $space-large 0;
    width: 100%;

    &-title {
      margin-bottom: $space-small;
    }

    .b2-c-autocomplete-input {
      margin-bottom: $space-medium;
    }
  }

  &__filter {
    &-item {
      align-items: center;
      border-bottom: 1px solid $palette-21;
      display: flex;
      padding: $space-small 0;
    }

    &-name {
      flex-grow: 1;
      font-size: $font-size-small;
    }
  }
}

$vats-class: ".#{$class-component-prefix}vats";

#{$vats-class} {
  &__description,
  &__percent {
    font-size: $font-size-small;
    padding: $space-small;
  }

  &__description {
    text-transform: capitalize;
  }
}

$order-details-class: ".#{$class-component-prefix}order-details";

#{$order-details-class} {
  &__search {
    display: flex;

    .b2-c-text-input__input {
      &::placeholder {
        color: #bbb;
      }
    }
  }

  &__browse-button {
    flex-shrink: 0;
    margin-left: $space-medium;
  }

  &__products {
    padding-bottom: $space-regular;

    &:empty {
      margin: 0;
      padding: 0;
    }
  }

  &__shipping {
    margin-bottom: $space-large;
  }

  &__shipping-title {
    font-size: $font-size-small;
    margin-bottom: $space-small;
  }

  &__calculations {
    margin-bottom: $space-medium;
  }

  &__calculations-title {
    font-size: $font-size-small;
    margin-bottom: $space-small;
  }

  &__calculations-textarea {
    border-color: $palette-22;
    font-size: $font-size-small;
    padding: $space-xsmall;
    resize: vertical;
    width: 100%;
  }

  &__calculations-row {
    display: flex;
    margin-top: $space-xsmall;
  }

  &__calculations-title {
    align-self: baseline;
    color: $palette-17;
    flex-grow: 1;
    font-size: $font-size-small;
    justify-content: flex-end;
  }

  &__calculations-value {
    align-self: baseline;
    color: $palette-17;
    font-size: $font-size-medium;
    justify-content: flex-end;
  }

  &__calculations-total {
    border: 0;
    color: #000;
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    margin: 0;
    padding: 0;
    text-align: right;
    width: 100%;
  }

  &__product {
    border-bottom: 1px solid #eee;
    padding: $space-small 0;
  }

  &__product-image-wrapper {
    background-color: $palette-01;
    height: 60px;
    margin-right: $space-small;
    min-width: 60px;
    width: 60px;
  }

  &__product-image {
    display: block;
    height: 100%;
    margin-right: $space-small;
    object-fit: cover;
    width: 100%;
  }

  &__product-data {
    flex-direction: column;
    flex-grow: 1;
  }

  &__product-title {
    color: #000;
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    margin-bottom: $space-xsmall;
  }

  &__product-sku {
    color: $palette-18;
    flex-shrink: 0;
    font-size: $font-size-small;
    font-weight: $font-weight-thin;
  }

  &__product-prices {
    align-items: center;
    display: flex;
  }

  &__product-price {
    border: none;
    font-size: $font-size-small;
    text-align: left;
    min-width: 70px;
  }

  &__product-multiplier {
    color: $palette-18;
    font-size: $font-size-xsmall;
    font-weight: $font-weight-bold;
    margin: 0 $space-small;
  }

  &__product-quantity {
    margin-right: $space-small;

    .b2-c-text-input {
      &__input {
        margin-bottom: 0;
        width: 70px;
      }
    }
  }

  &__product-total-price {
    border: none;
    flex-grow: 1;
    text-align: right;
    width: 100%;
  }

  &__product-remove {
    color: $palette-13;
    flex-shrink: 0;
    font-size: $font-size-large;
    padding: 15px 15px 15px 15px;

    &:hover {
      cursor: pointer;
    }
  }

  &__payment {
    align-items: center;
    border-top: 1px solid $palette-22;
    display: flex;
    margin-bottom: 15px;
    padding-top: 15px;
  }

  &__payment-header {
    display: flex;
    flex-grow: 1;
  }

  &__payment-icon {
    color: $palette-14;
    font-size: $font-size-large;
    margin-right: 20px;
    min-width: 30px;
  }

  &__payment-title {
    align-self: center;
    font-size: $font-size-small;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__actions {
    button {
      margin-left: 5px;
    }
  }

  &__button {
    display: block;
    margin-bottom: $space-small;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__address {
    display: flex;
    flex-direction: column;
  }

  &__address-item {
    font-size: $font-size-small;
    margin-bottom: $space-xsmall;
  }

  &__action-button {
    margin: 0;
  }
}

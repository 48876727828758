$settings-section-class: ".#{$class-component-prefix}settings-section";

#{$settings-section-class} {
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1;
  margin: 0 $space-medium $space-medium 0;
  padding-top: $space-small;

  &__title {
    color: $palette-14;
    font-size: $font-size-large;
    margin-bottom: $space-small;
  }

  &__description {
    color: $palette-18;
    font-size: $font-size-small;
  }
}

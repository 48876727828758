$email-class: ".#{$class-component-prefix}email";

#{$email-class} {
  display: flex;
  flex-direction: column;

  &__action-button {
    margin-left: $space-small;
  }

  &__provider {
    flex-grow: 1;
  }

  &__code {
    display: flex;
  }

  &__code-input {
    flex-grow: 1;
    margin-right: $space-medium;

    input {
      margin-bottom: 0;
    }

    ::placeholder {
      color: #888;
    }
  }

  &__code-button {
    margin-right: $space-small;
  }

  &__code-ok {
    font-size: $font-size-small;
  }

  &__message {
    margin-bottom: $space-small;
  }

  &__message-reset {
    margin-bottom: $space-medium;
  }

  &__message-button {
  }

  &__smtp-message {
    font-size: $font-size-small;
    margin-top: $space-medium;
  }
}

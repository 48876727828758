/**
* Color Palette
*/

$palette-01: #f2f2f3;
$palette-02: #5dbfe2;
$palette-03: rgba(115, 169, 247, 0.2);
$palette-04: rgba(255, 255, 255, 0.2);
$palette-05: rgba(255, 255, 255, 0.4);
$palette-06: black;
$palette-07: black;
$palette-08: black;
$palette-09: black;

$palette-10: #00084b;
$palette-11: #1c2260;
$palette-12: #43467f;
$palette-13: #393c74;
$palette-14: #6266a7;
$palette-15: #f4f6f8;
$palette-16: #777;
$palette-17: #666;
$palette-18: #999;
$palette-19: #ffffff;
$palette-20: rgba(98, 102, 167, 0.5);
$palette-21: #ccc;
$palette-22: #ddd;
$palette-23: #7d8c9b;
$palette-24: #eee;

$palette-30: #aed581; // success
$palette-31: #ffb300; // warning
$palette-32: #ff5252; // error
$palette-33: #bbdefb; // info

$palette-34: #fdd22b;
$palette-35: #34bcec;
$palette-36: rgba(0, 0, 0, 0.4);

/**
* Shade Definitions
*/

$shade-definition-85: 0.85 !default;
$shade-definition-50: 0.5 !default;

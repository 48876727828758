$login-class: ".#{$class-component-prefix}login";

#{$login-class} {
  align-items: center;
  background-color: $palette-15;
  display: flex;
  flex: 1;
  height: 100vh;
  justify-content: center;

  &__form {
    background-color: #fff;
    border: 1px solid #eee;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.05);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
    height: 500px;
    margin-bottom: 20vh;
    padding: 45px 55px;
    position: relative;
    width: 500px;
  }

  &__form-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__form-back {
    font-size: 24px;
    left: 0;
    line-height: 40px;
    margin-left: 30px;
    position: absolute;

    &:hover {
      cursor: pointer;
    }
  }

  &__form-title {
    margin-bottom: 40px;
    text-align: center;
  }

  &__form-loading {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  &__form-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &__form-fields {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__form-invalid {
    color: $palette-23;
    font-size: $font-size-small;
    margin-bottom: $space-xxlarge;
    text-align: center;
  }

  &__form-spacer {
  }

  &__field {
    margin-bottom: 10px;
  }

  &__button-login {
    margin-bottom: 10px;
    span {
      width: 100%;
    }
  }

  &__button-reset {
    width: 100%;
    span {
      text-align: center;
      font-size: $font-size-small;
      width: 100%;
    }
  }
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.fade-enter-done {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
